@import "variables.scss";
@import "external.scss";
@import "easing.scss";
@import "animations.scss";

html {
  font-size: 14px;
}
body {
  background-color: #f4f6f9 !important;
  margin: 0;
  padding: 0 0 0 0;
  font-family: 'Baloo Bhaina 2', 'Roboto', sans-serif;
  color: #333;
  -webkit-font-smoothing: subpixel-antialiased;
  .module-content {
    margin-left: 300px;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.App {
  height: 100vh;
  width: 100vw;
  .module-content{
    padding-top: 64px;
    height: calc(100% - #{$footer-height});
    overflow: scroll;
  }
}

.small-title {
  font-size: 14px;
  font-weight: bold;
  color: #434343;
}

.ml-20 {
  margin-left: 20px;
}

.menu-go-list {
  .menu-go-list-item {
    border-bottom: 1px solid #DEDEDE;
    min-height: 62px;
    &:last-child {
      border-bottom: 0;
    }
  }
}


button:focus {
  outline: 0
}


