.menu-header {
  padding: 20px;
  background-color: #343371;
  text-align: center;
  color: #F0F0F0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  h4 {
    font-size: 26px;
  }
}

.menu-offset-button {
  animation-delay: 300ms;
  position: absolute;
  top: 0;
  height: 100%;
  right: -55px;
  justify-content: center;
  align-items: center;
  display: flex
}

.is-clickable {
  transition: background-color 150ms ease-out;

  &:hover {
    background-color: #6fdcff!important;
  }
}

.mg-menu-contents {
  width: 100%;
  border-radius: 4px;
  padding: 10px 10px 10px 10px;
  border: 0 dashed transparent;
  transition: width 150ms ease-out, border 150ms ease-out, padding 150ms ease-out, margin-bottom 150ms ease-out;
  position: relative;

  .menu-draggable {
    position: relative;
    border: 2px solid transparent;
    padding-right: 0;
    border-radius: 3px;
    transition: padding 300ms ease-out, border 300ms ease-out;
    margin-bottom: 5px;

    &.menu-draggable-active {
      background-color: #FFF;
      padding: 10px 40px 10px 10px;
      border: 2px dashed #323232;
    }
  }

  .menu-drag-handle {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 3px;
    background-color: #E0E0E0;
    height: calc(100% - 20px);
    justify-content: center;
    align-items: center;
    display: flex;

    &.hidden {
      display: none;
    }
  }

  &.editing {
    padding-right: 50px;
    //border: 2px dashed #323232;

    //width: calc(100% - 25px);
  }
}

.price-tag {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.food-price {
  .units {
    font-weight: 700;
    font-size: 20px;
  }

  .decimals {
    font-weight: 700;
    font-size: 13px;
  }
}

.menu-food {
  //position: relative;
}

.edit-toolbar {
  background-color: #4e5994;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  height: 50px;
  flex-direction: row;
  padding-right: 20px;
}

.mg-menu-contents {

  .mg-menu-items {
    .menu-category {
      margin-bottom: 40px;
      //background-color: #F0F0F0;
      $width: 60%;

      &:after {
        content: "";
        border-bottom: 1px solid #E0E0E0;
        width: $width;
        position: absolute;
        bottom: 15px;
        left: #{(100% - $width) / 2};
        z-index: 1;
      }
    }

    > div:last-child {
      .menu-category {
        margin-bottom: 0;

        &:after {
          display: none;
        }
      }
    }

  }

  &.editing {
    .mg-menu-items {
    }
    .menu-category {
      margin-bottom: 20px;
      &:after {
        display: none;
      }
    }
  }


}