@keyframes fadeInUpShort {
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpShort {
  animation-name: fadeInUpShort;
}