.panel {
  //padding: 10px;
  border-radius: 3px;
  background-color: #FFF;
  margin-bottom: 20px;
  &.panel-default {
    border: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    > {
      .panel-footer, .panel-heading {
        background-color: #fff;
        //background-color: #434343;
        border: none;
        color: #b0b0b0;
        font-size: 12px;
        line-height: 40px;
        padding: 0 20px;
        //font-weight: 700;
      }
      .panel-heading {
        border-bottom: 1px solid #F0F0F0;
        //line-height: 20px;
      }
      .panel-body {
        padding: 15px 20px 15px 20px;
      }
      .panel-footer > hr, .panel-heading > hr {
        margin: 5px 10px 5px 0;
      }
      .panel-footer > .panel-footer, .panel-heading > .panel-footer {
        padding: 10px;
      }
    }
  }
  .list-group-item {
    border-color: #f7f7f7;
  }
  &.no-borders {
    border-width: 0;
    .panel-footer, .panel-heading {
      border-width: 0;
    }
  }
  .table {
    > tbody > tr:first-child > td {
      border-top: none !important;
    }
    td, th {
      padding: 6px 15px;
      border-top: none;
    }
    thead > tr > th {
      border-bottom: 1px solid #ebebeb;
    }
  }
  .table-striped > {
    tbody > tr:nth-child(odd) > {
      td, th {
        background-color: #f9f9f9;
      }
    }
    thead th {
      background: #fff;
      border-right: none;
      &:last-child {
        border-right: none;
      }
    }
  }
}

.panel-actions {
  position: absolute;
  top: 0;
  right: 10px;
}

.panel-heading {
  border-radius: 3px 3px 0 0;
  cursor: pointer;
  position: relative;
  &.no-border {
    margin: -1px -1px 0;
    border: none;
  }
  .nav {
    font-size: 13px;
    margin: -10px -15px -11px;
    border: none;
    > li > a {
      border-radius: 0;
      margin: 0;
      border-width: 0;
    }
  }
  .nav-tabs {
    &.nav-justified {
      width: auto;
      > li:first-child > a {
        border-radius: 3px 0 0;
      }
    }
    &.pull-left > li:first-child > a {
      border-radius: 3px 0 0;
    }
    &.nav-justified > li:last-child > a, &.pull-right > li:last-child > a {
      border-radius: 0 3px 0 0;
    }
    > li {
      > a {
        line-height: 1.5;
        &:focus, &:hover {
          border-width: 0;
          background: 0 0;
          border-color: transparent;
        }
      }
      &.active > a {
        color: #717171;
        background: #fff;
        &:focus, &:hover {
          color: #717171;
          background: #fff;
        }
      }
    }
  }
  .list-group {
    background: 0 0;
  }
}

.panel-footer {
  border-radius: 0 0 3px 3px;
  padding-bottom: 10px !important;
  padding-top: 0 !important;
}

.panel-heading {
  text-transform: uppercase;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 1px solid #eaedef;
}

