.fat-button {
  position: relative;
  //border-bottom: 1px solid rgba(230, 230, 230, .3);
  cursor: pointer;
  display: block;
  //color: #FFF !important;
  background: transparent;
  color: #DEDEDE;
  border: 0;
  border-bottom: 1px solid rgba(30, 30, 30, 0.5);
  //border-bottom: 1px solid rgba(230, 230, 230, 0.3);
  width: 100%;
  transition: background-color ease-out 200ms;

  padding: 0;

  .title {
    display: block;
    vertical-align: middle;
    font-weight: normal;
    font-size: 11px;
    line-height: 50px;
    text-transform: uppercase;
    margin-left: 50px;
    padding-left: 10px;
    text-align: left;
  }
  .icon {
    position: absolute;
    top:0;
    left:0;
    line-height: 50px;
    width: 50px;
    text-align: center;
  }
  &:hover {
    background-color: rgba(0, 0, 0, .1);
  }
}