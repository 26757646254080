.error-component {
  text-align: center;
  padding: 60px 0;
  width: 800px;
  margin: 40px auto;
  .icon {
    position: relative;
    display: inline-block;
    line-height: 120px;
    .fa-map-o {
      text-shadow: 0 2px 4px rgba(0, 0, 0, .3);
    }
    .fa-search {
      position: absolute !important;
      left: 50px;
      top: 40px;
      text-shadow: 0 2px 4px rgba(0, 0, 0, .3);
    }
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #555;
  }
  .description {
    font-size: 14px;
    color: #999;
  }
}

.callout {
  margin: 20px 0;
  padding: 20px;
  border-left: 3px solid #eee;
  p {
    line-height: 20px;
  }
}

.callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1.25rem;
  line-height: 1.5
}

.callout p:last-child {
  margin-bottom: 0
}

.callout-success {
  background-color: rgba(102, 187, 106, .08);
  border-color: #66bb6a
}

.callout-success h4 {
  color: #66bb6a
}

.callout-info {
  background-color: rgba(0, 188, 212, .05);
  border-color: #00bcd4
}

.callout-info h4 {
  color: #00bcd4
}

.callout-warning {
  background-color: rgba(255, 202, 40, .08);
  border-color: #ffca28
}

.callout-warning h4 {
  color: #ffca28
}

.callout-danger {
  background-color: rgba(239, 83, 80, .05);
  border-color: #ef5350
}

.callout-danger h4 {
  color: #ef5350
}