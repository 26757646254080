.page {
  width: 100%;
  height: 100%;
  //margin: auto;
  transition: width 500ms ease-out;
}
.with-animations .page {
  animation-duration: 700ms;
}

.page.darken {
  background-color: #434343;
}

.page-content {
  width: 100%;
  padding: 20px 10px;
  margin: 0 auto;
  //display: flex;
  height: 100%;
  > .container {
    padding: 20px;
  }
}


.page.full-width .page-content {
  transition: width 500ms ease-out;
  width: 100%;
  padding: 0;
}

@media screen and (min-width: 1080px) {
  .page-content {
    width: 98%;
    padding: 20px 40px;
    height: 100%;
  }
}

@media screen and (min-width: 1240px) {

  .page-content {
    width: 1200px;
  }
}

@media screen and (min-width: 1500px) {
  .page-content {
    width: 1400px;
  }
}

.home-jumbo {
  //background: url('../../assets/images/background-purple.jpg');
  //background: url('/assets/images/vishwas-katti-UrQ_BS9bLXQ-unsplash.jpg');

  background: linear-gradient( rgba(0,0,0,.6), rgba(0,0,0,0));

  //background-position: 50% 100%;
  padding: 60px 0;
  color: #FFF;
}
