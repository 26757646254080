

footer {
  //margin-top: 100px;
  //padding-top: 100px;
  //padding-bottom: 100px;
  height: 50px;
  background-color: #FFF;
  color: #434343;
  border-top: 1px solid #F0F0F0;
  //background: url('') 50% 0 repeat-x;
  //background-size: cover;
  font-size: 14px;
  font-family: 'Baloo Bhaina 2', Helvetica, Roboto, Arial, sans-serif;
  //.logo {
  //  font-size: 30px;
  //}

  //.container {
  //  height: 100%;
  //  justify-content: center;
  //  align-items: center;
  //  display: flex;
  //  p {
  //    display: block;
  //    padding: 0;
  //    margin: 0;
  //  }
  //}
}